import React from "react";

// Import free icon packs from Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

// Add the necessary icon packs to the library
library.add(fab, fas, far);

export const Icon = (props) => {
    const { icon, pack, color, size, className } = props;

    return (
        <span className={color}>
            <FontAwesomeIcon icon={[pack, icon]} size={size} className={className} />
        </span>
    )
};