import React from "react";
import './CopyrightNote.css';

const CopyrighNote = () => {

    return (
        <section className="py-2 bg-grey-000 fs-7">
            <div className="container">
                <div>
                <p class="p-0" id="sp4Hint">Brought to you with 💜 and 💡 by <a href="https://www.space-4.de/" target="_blank">Space Four</a></p>
                </div>
            </div>
        </section>
    )
};

export default CopyrighNote;