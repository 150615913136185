import React, {useEffect} from "react";
import "./AppHeader.css";

import { NavItem } from "../../components/ui/NavItem/NavItem.jsx";
import { LoginLogout } from "../../components/ui/LoginLogout/LoginLogout.jsx";
import { NavbarLogo } from "../../components/ui/NavbarLogo/NavbarLogo.jsx";
import { ThemeToggle } from "../../components/ui/ThemeToggle/ThemeToggle.jsx";
import { MenuToggle } from "../../components/ui/MenuToggle/MenuToggle.jsx";

const AppHeader = () => {

    // Add a shadow to the navbar when the user scrolls down
    const handleScroll = () => {
        const scrollPosition = window.scrollY; // => scroll position
        if (scrollPosition > 20) {
            document.querySelector(".navbar").classList.add("elev-300-navbar");
        } else if (scrollPosition < 21) {
            document.querySelector(".navbar").classList.remove("elev-300-navbar");
        }
    };
    
    // Add an event listener to the window to listen for scroll events
    useEffect(() => {
      handleScroll();
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return (
        <nav className="navbar navbar-expand-lg p-3 fixed-top bg-background-color">
            <div className="container">
                <div className="me-3">
                    <NavbarLogo />
                </div>

                <MenuToggle />

                <div className="collapse navbar-collapse justify-content-between mb-3 mb-lg-0" id="navbarNav">
                    <div className="d-flex justify-content-end justify-content-lg-center flex-grow-1">
                        <ul className="navbar-nav mt-5 mt-lg-0 align-items-end">
                            <NavItem label="Start here" referrer="dashboard" requiresAuth />
                            <NavItem label="Alle Beiträge" referrer="posts" requiresAuth />
                        </ul>
                    </div>
                    <hr />
                    <ul className="navbar-nav d-flex flex-column align-items-lg-center my-3 my-lg-0">
                        <p className="fs-7 fw-bold col-grey-900 mb-3 d-lg-none">Benötigen Sie Hilfe? Zögern Sie nicht, uns zu kontaktieren!</p>
                        <div className="d-flex flex-row">
                        <ThemeToggle />
                        <LoginLogout />
                        </div>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default AppHeader;