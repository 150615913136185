import { useEffect, useState, useContext } from 'react';
import './PostDetailPage.css';
import { useParams } from 'react-router-dom';

import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import { API } from '../../data/constant.js'
import { AuthContext } from '../../context/AuthProvider.js';
import { LoadingSpinner } from '../../components/ui/LoadingSpinner/LoadingSpinner.jsx';
import { FormattedDate } from '../../components/ui/DatesAndTimes/FormattedDate/FormattedDate.jsx';
import { DateLabel } from '../../components/ui/DatesAndTimes/DateLabel/DateLabel.jsx';

import StandardLayout from "../../layouts/StandardLayout";
import CtaSectionSupport from '../../components/sections/CtaSectionSupport/CtaSectionSupport.jsx';

export const PostDetails = () => {
  // Get the post id from the URL params
  const { id } = useParams();

  // Get the token from the AuthContext
  const { auth } = useContext(AuthContext);

  // Create a post state to store the post data
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch post data from API using the post id and store it in the post state 
  useEffect(() => {
    setIsLoading(true);
    const fetchPost = async () => {
      try {
      const response = await fetch(`${API}/posts/${id}`, {
        headers: {
            Authorization: `Bearer ${auth?.accessToken}`
        },
      });
      const data = await response.json();
      setPost(data);
      } catch (error) {
          console.log(error.message);
      } finally {
          setIsLoading(false);
      };
    };
    
    fetchPost();
  }, [id]);

  // If post is null, display loading spinner
  if (isLoading || !post) {
    return (
      <div className="container">
        <LoadingSpinner />
      </div>
      ) 
      
  }

  return (
    <StandardLayout>
      <div className='container mb-5 d-flex justify-content-center'>
        <div className="post-container">

        <h1>{post.data.attributes.title}</h1>

        <div className="p-3 bg-grey-100 mt-3 mb-3 col-white elev-300 rounded">
          <p>{post.data.attributes.shortDescription}</p>
        </div>

        <div class="academy-md-styles py-5">
          <Markdown remarkPlugins={[remarkGfm]}>
            {post.data.attributes.body}
          </Markdown>
        </div>
        </div>
      </div>

      <CtaSectionSupport />
    </StandardLayout>
  );
};
