import React, {useContext} from 'react';
import { Routes, Route } from 'react-router-dom';

import { ProtectedRoute } from './components/AuthProvider/ProtectedRoute.jsx';
import { AuthContext } from './context/AuthProvider.js';

import { SignUp } from './pages/SignUp/SignUp.jsx';
import { SignIn } from './pages/SignIn/SignIn.jsx';
import { Dashboard } from './pages/Dashboard/Dashboard.jsx';
import { Posts } from './pages/Posts/Posts.jsx';
import { PostDetails } from './pages/PostDetailPage/PostDetailPage.jsx';
import { Homepage } from './pages/Homepage/Homepage.jsx';

const AppRoutes = () => {

    const { auth } = useContext(AuthContext);

    return (
        <Routes>
            <Route path="/sign-up" element={ <SignUp />} />
            <Route path="/sign-in" element={ <SignIn />} />
            <Route path="/" element={ <Homepage />} />
            <Route path="/dashboard" element={<ProtectedRoute auth={auth}><Dashboard /></ProtectedRoute>} /> 
            <Route path="/posts/:id" element={<ProtectedRoute auth={auth}><PostDetails /></ProtectedRoute>} /> 
            <Route path="/posts" element={<ProtectedRoute auth={auth}><Posts /></ProtectedRoute>} /> 
        </Routes>
    );
};

export default AppRoutes;