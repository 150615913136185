import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './LoginPageAd.css'

const LoginPageAd = () => {
    return (
        <section className="p-5" id='LoginPage'>
            <div className="container">
                <h2 className="fw-bold mb-5 ms-1 fs-1 text-center text-md-start">
                    Die Zukunft Ihres Studienerfolgs beginnt bei Trialytix
                </h2>
                <div className="row g-3 p-3 text-wrap text-start text-break">
                    {/* Column for Small Screens - Two Columns Layout */}
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="d-flex flex-column align-items-start text-start text-md-start">
                            <FontAwesomeIcon className='iconLogin' icon="fa-solid fa-headset" size='2x'/>
                            <p className="fw-bold fs-5 accentText-2 mb-3">Experten Coaching</p>
                            <p>Entdecken Sie die Features und Reports von Trialytix und werden Sie mit unserer benutzerfreundlichen Technologie vertraut.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="d-flex flex-column align-items-start text-start text-md-start">
                            <FontAwesomeIcon className='iconLogin' icon="fa-solid fa-rocket" size='2x'/>
                            <p className="fw-bold fs-5 accentText-2 mb-3">Schnelle Einrichtung</p>
                            <p>Erhalten Sie Zugang zu Ihrem individuellen Trialytix-Workspace und beginnen Sie mit der Transformation Ihres Studienmanagements</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="d-flex flex-column align-items-start text-start text-md-start">
                            <FontAwesomeIcon className='iconLogin' icon="fa-solid fa-link" size='2x'/>
                            <p className="fw-bold fs-5 accentText-2 mb-3">Reibungsloser Go-Live</p>
                            <p>Unsere Experten führen Sie durch einen nahtlosen Integrationsprozess und sorgen für eine reibungslosen Einführung von Trialytix</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="d-flex flex-column align-items-start text-start text-md-start">
                            <FontAwesomeIcon className='iconLogin' icon="fa-solid fa-bullseye" size='2x'/>
                            <p className="fw-bold fs-5 accentText-2 mb-3">Kontinuierlicher Support</p>
                            <p>Unser fortlaufender Support hilft Ihnen, die Nutzung von Trialytix kontinuierlich zu verbessern und die Effizienz zu steigern</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LoginPageAd;
