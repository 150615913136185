import React, { useContext } from "react";
import { Link } from "react-router-dom";
import './NavbarLogo.css'
import { ThemeContext } from "../../../context/ThemeProvider";

export const NavbarLogo = ({ forceDarkMode = false }) => {
    // Get the current theme preference from the context
    const { preference } = useContext(ThemeContext);

    // Determine which logo to use
    const logoSrc = forceDarkMode || preference === true
        ? require(`../../../assets/brand/clientlogo_darkmode.png`)
        : require(`../../../assets/brand/clientlogo.png`);

    return (
        <div className="d-flex align-items-center">
            <Link to="https://www.trialytix.io">
                <img 
                    className="img-fluid navbar-logo me-2 navbar-logos" 
                    src={logoSrc}
                    alt="Client Logo"
                />
            </Link> 
            <Link to="/" >
                <p className="fw-bold navbar-academy-text navbar-logos">Academy</p>
            </Link>
        </div>
    )
};
