import React, {useContext} from 'react';
import './Homepage.css';
import { Link } from 'react-router-dom';

import LandingPageLayout from '../../layouts/LandingPageLayout/LandingPageLayout';
import { AuthContext } from '../../context/AuthProvider';

import { RouterLink } from '../../components/ui/Link/Link'; 
import { CtaSectionWaves } from '../../components/sections/CtaSection/CtaSectionWaves/CtaSectionWaves';
import LoginPageAd from '../../components/sections/LoginPageAd/LoginPageAd';

export const Homepage = () => {
    const { isAuthenticated } = useContext(AuthContext);

    return (
        <>
            <LandingPageLayout>
                <section id="Hero-Landing" className='d-flex align-items-center py-5'>
                    <div className="container my-5">
                        <div id="Main-Section" className="row g-md-5">
                            <div className="col-12 col-md-7">
                                <h1 className="mb-3 fw-bold display-5 accentText">Guidelines & Workflows für deinen Start mit Trialytix</h1>
                                <p> 
                                    Lerne unsere Wissensdatenbank kennen und erfahre mehr über Trialytix in unseren Anleitungen, Best-Practice-Workflows und Tutorials für den optimalen Einstieg in Trialytix
                                </p>
                                {
                                        isAuthenticated === false ?
                                            <div>
                                                <Link to="/sign-in" className="btn btn-primary me-3">
                                                Einloggen
                                                </Link>
                                                <Link to="/sign-up" className="btn btn-secondary">
                                                    Zugang anfragen
                                                </Link>
                                            </div>
                                        : <RouterLink referrer="/dashboard" label="Zur Academy" classes="col-definitive-black" />
                                    }
                            </div>

                            <div className="col-12 col-md-5 d-flex align-items-center mt-3">
                                <div>
                                <img 
                                    src="/Home-Hero-Section.png" 
                                    alt="Home Hero" 
                                    className="hero-image"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>         
                  <LoginPageAd />
                <CtaSectionWaves />
            </LandingPageLayout>
        </>
    );
};