import React from "react";

export const FormattedDate = (props) => {
    
  const date = new Date(props.timestamp);
  const formattedDate = date.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });

  return (
    <>
      {formattedDate}
    </>
  );
};