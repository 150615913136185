import React, {useContext, useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';

import './Posts.css';
import { useFetch } from '../../hooks/useFetch.js';
import { AuthContext } from '../../context/AuthProvider.js';

import StandardLayout from '../../layouts/StandardLayout.jsx';

import { PostList } from '../../components/ui/PostList/PostList.jsx';
import { PostCatSelector } from '../../features/PostCatSelector/PostCatSelector.jsx';

import CtaSectionSupport from '../../components/sections/CtaSectionSupport/CtaSectionSupport.jsx';

export const Posts = () => {
    const [selectedFilter, setSelectedFilter] = useState(null);

    // Get the filter from the URL query params and convert it to a number if it's a number
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('filter');
    const filter = isNaN(paramValue) ? paramValue : Number(paramValue);

    // Set the selected filter to the filter from the URL query params
    useEffect(() => {
        if (filter) {
            setSelectedFilter(filter);
        }
    }, [filter]);

    // Get the token from the AuthContext
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;

    // Fetch the posts and categories, destructuring the data, loading and error states into own variables
    // e.g. taking the data property from the object returned by useFetch and assign it to a new variable named posts
    const { data: posts, loading: postsLoading, error: postsError } = useFetch(`/post-list`, token);
    const { data: categories, loading: categoriesLoading, error: categoriesError } = useFetch(`/post-categories`, token);

    // Handle loading and error states for both fetch requests
    const loading = postsLoading || categoriesLoading;
    const error = postsError || categoriesError;

    // Filter the posts based on the selected filter
    let displayPosts = posts;

    if (displayPosts) {
        if (selectedFilter === 'liked') {
            displayPosts = displayPosts.filter(post => post.post_likes.length > 0);
        } else if (selectedFilter) {
            displayPosts = displayPosts.filter(post => post.post_category.id === selectedFilter);
        }
    }

    // If the clicked filter is active, deactivate it, otherwise activate it
    const toggleFilter = (filter) => {
        setSelectedFilter(prevFilter => prevFilter === filter ? null : filter);
    };
    
    // Clear the filters
    const clearFilter = () => setSelectedFilter(null);

    return (
        <StandardLayout>
            <div>
                <div className="container">
                    <div className="row pb-3 pb-3">
                    <h6 className="accentHeading col-primary">Alle Beiträge</h6>
                    <h1 className="display-6 fw-bold pb-3">Entdecke die <span className="accentText">Trialytix</span> Academy</h1>
                    <p className="lead">Guidelines und Workflows für deinen Start mit Trialytix</p>
              </div>
                </div>
            </div>


            <div className="container mt-2 mb-5">
                <PostCatSelector 
                    categories={categories} 
                    selectedFilter={selectedFilter} 
                    toggleFilter={toggleFilter} 
                    clearFilter={clearFilter} 
                    isLoading={loading}
                    error={error}
                />
            </div>

            <div className="container mb-5">       
                <PostList 
                    posts={displayPosts} 
                    selectedFilter={selectedFilter} 
                    isLoading={loading} 
                    error={error} 
                />
            </div>

            <CtaSectionSupport />
        </StandardLayout>
    )
};