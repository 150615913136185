import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { findSignUpErrorMessage } from './SignUpErrors.js'

import { useAuth } from "../../hooks/useAuth.js";
import { LoadingSpinner } from "../../components/ui/LoadingSpinner/LoadingSpinner";
import { API } from '../../data/constant.js'

// Regexes for username, password and email validation
const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const SignUpForm = () => {
    const userRef = useRef();
    const { setAuth } = useAuth();

    const navigate = useNavigate();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    // Set Focus to the username input field on page load (note empty dependency array)
    useEffect(() => {
        userRef.current.focus();
    }, []);

    // Validate the username when the user state changes
    useEffect(() => {
        const result = USER_REGEX.test(user);
        setValidName(result);
    }, [user]);

    useEffect(() => {
      const result = EMAIL_REGEX.test(email);
      setValidEmail(result);
    }, [email]);

    // Validate the password when the pwd state changes
    // Then pwd and matchpPwd are in sync: 
    // Anytime one of them changes they are compared and the match state is set
    useEffect(() => {
        const result = PASSWORD_REGEX.test(pwd);
        setValidPwd(result);
        const match = pwd === matchPwd;
        setValidMatch(match);
    }, [pwd, matchPwd]);

    // Anytime the user changes either user, pwd or matchPwd, the error message is cleared (because user saw it and is typing)
    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd]);

    
    const getButtonDisabledReason = () => {
      if (!user) return "Username is required.";
      if (!validName) return "Username is not valid.";
      if (!email) return "Email is required.";
      if (!validEmail) return "Email is not valid.";
      if (!pwd) return "Password is required.";
      if (!validPwd) return "Password is not valid.";
      if (!matchPwd) return "Please confirm your password.";
      if (!validMatch) return "Passwords do not match.";
      return null; // Button is not disabled
    }
  

    const handleSubmit = async (e) => {
      e.preventDefault(); // Prevent the default form submit action (Page reload)
      setIsLoading(true);
      
      // If Button is enabled with JS hack: Return if no entry was made that passed our validation anyway
      const v1 = USER_REGEX.test(user);
      const v2 = PASSWORD_REGEX.test(pwd);
      if (!v1 || !v2) {
        setErrMsg('Invalid Entry');
        return;
      }

      const values = {
        username: user,
        email: email,
        password: pwd,
      }

      try {
        const response = await fetch(`${API}/auth/local/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });
  
        const data = await response.json();

        const accessToken = data?.jwt;
        const user = data?.user;

        if (data?.error) {
          throw data?.error;
        } else {
          setAuth({ accessToken, user });
          navigate('/dashboard', { replace: true });
        }
      } catch (error) {
        console.error(error);
        setErrMsg(error.status);
      } finally {
        setIsLoading(false);
      }
    };

    if (isLoading) {
      return <LoadingSpinner />;
    }

    const buttonDisabledReason = getButtonDisabledReason();
    const isButtonDisabled = buttonDisabledReason !== null;


    return (
      <>      
        <form className="form-horizontal" onSubmit={handleSubmit}>
            <div className="form-group mb-2">
              <label htmlFor="username" className="control-label mb-2 fw-bold">
                  Benutzer:innen-Name:
              </label>
              <input 
                type="text" 
                id="username" 
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)} // This ties the user's input to the state
                required
                aria-invalid={validName ? 'false' : 'true'}
                onFocus={() => setUserFocus(true)}
                onBlur={() => setUserFocus(false)}
                className="form-control" 
                placeholder="Benutzer:innen-Name" 
              />
                {/* Hints for user displayed only when form input is focused, input exists and is not yet valid */}
                <p className={userFocus && user && !validName ? '' : 'd-none'}>
                  4 to 24 characters <br />
                  Must begin with a letter <br />
                  Letters, numbers, hyphens and underscores only
                </p>
            </div>

            <div className="form-group mb-4">
              <label htmlFor="username" className="control-label mb-2 fw-bold">
                  Email Adresse
              </label>
              <input 
                type="email" 
                id="email" 
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)} // This ties the user's input to the state
                required
                aria-invalid={validName ? 'false' : 'true'}
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
                className="form-control" 
                placeholder="max.mustermann@mustermail.com" 
              />
              {/* Hints for user displayed only when form input is focused, input exists and is not yet valid */}
              <p className={emailFocus && email && !validEmail ? '' : 'd-none'}>
                  Please provide a valid email address
                </p>
            </div>

            <div className="form-group mb-2">
            <label htmlFor="password" className="control-label mb-2 fw-bold">
              Passwort
              </label>
              <input
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)} // This ties the user's input to the state
                required
                aria-invalid={validPwd ? 'false' : 'true'}
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
                className="form-control"
                placeholder="Passwort"
              />
              {/* Hints for user displayed only when form input is focused, input exists and is not yet valid */}
              <p className={pwdFocus && !validPwd ? '' : 'd-none'}>
                  8 to 24 characters <br />
                  Must include uppercase, lowercase, number and special character <br />
                  Allowed special characters: ! @ # $ %
                </p>
            </div>

            <div className="form-group mb-4">
              <input
                type="password"
                id="confirm-password"
                onChange={(e) => setMatchPwd(e.target.value)} // This ties the user's input to the state
                required
                aria-invalid={validMatch ? 'false' : 'true'}
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
                className="form-control"
                placeholder="Passwort bestätigen"
              />
              {/* Hints for user displayed only when form input is focused, input exists and is not yet valid */}
              <p className={matchFocus && !validMatch ? '' : 'd-none'}>
                  Must match password
                </p>
            </div>

            {errMsg && (
              <div className="form-group my-3">
                <div className="col-sm-offset-2 col-sm-10">
                  <div className="alert alert-danger">{findSignUpErrorMessage(errMsg)}</div>
                </div>
              </div>
            )}

             {/* Display the reason why the button is disabled, if any */}
        {isButtonDisabled && (
            <div className="alert alert-warning my-3">
                {buttonDisabledReason}
            </div>
        )}
            <div className="d-flex justify-content-center w-100">
            <button disabled={isButtonDisabled} className="btn btn-primary w-100" type="submit">
              Registrieren
            </button>
            </div>
        </form>
      </>
    )
};