import React from "react";

import { useAuth } from "../../hooks/useAuth";

import { RouterLink } from "../../components/ui/Link/Link";
import StandardLayout from "../../layouts/StandardLayout";
import { Card } from "../../components/ui/Card/Card";

import { LikedPostsWidget } from '../../components/ui/Dashboard/LikedPostsWidget/LikedPostsWidget';

import HeroImage from '../../assets/media/image/trialytix_careerVisual.webp';
import IconRocket from '../../assets/icons/trialytix/Rocket_Background.svg';
import CtaSectionSupport from "../../components/sections/CtaSectionSupport/CtaSectionSupport";


export const Dashboard = () => {
    const { auth } = useAuth();
    const user = auth?.user;

    return (
        <StandardLayout>
            <section className="my-5 py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                            <h1 className="fs-2 fw-semibold accentText">Willkommen bei Trialytix Academy</h1>
                            <p className="pt-3 fw-semibold lead col-grey-900">Erfahre mehr über Trialyix in unseren Anleitungen, Best-Practice-Workflows und Tutorials. Für den optimalen Einstieg in Trialytix und darüber hinaus!</p>
                        </div>

                        <div className="col-12 col-md-6 d-flex justify-content-end">
                            <img src={HeroImage} style={{ height: '350px' }} className="mt-5 md-mt-0"/>
                        </div>
                    </div>
                </div>
            </section>


            <div className="container mb-5">
                <div className="row g-5">
                    <div className="col-12 col-md-6 col-lg-5">
                        <Card>
                            <div className="card-body p-4">
                                <img src={IconRocket} style={{ height: '40px' }} className="mb-4"/>
                                <h5 className="card-title fs-3 my-3">Hallo <span className="fw-semibold">{user && user.username}</span>,</h5>
                                <p className="mb-5">wir freuen uns, dich auf deinem Weg zum erfolgreichen Management klinischer Studien zu unterstützen.</p>
                                <ul className="list-group list-group-flush list-unstyled">
                                    <li className="col-primary">
                                        <RouterLink referrer="/posts" label="Loslegen" />
                                    </li>
                                </ul>
                            </div>
                        </Card>
                    </div>

                    <div className="col-12 col-md-6 col-lg-7">
                        <LikedPostsWidget />
                    </div>
                </div>
            </div>

            <CtaSectionSupport />
            
        </StandardLayout>
    )
};