import React from 'react';

import { NavbarLogo } from '../../components/ui/NavbarLogo/NavbarLogo';
import { SocialIcons } from '../../components/ui/SocialIcons/SocialIcons';
import './AppFooter.css';

const AppFooter = () => {
    return (
        <section id="AppFooter" className='py-5 bg-secondary text-white'>
      <div className="container">
        <div className="row py-5">
          <div className="col-md-6 col-lg-4 mb-5">
            <h4 className="text-white mb-3">Sind Sie bereit, das Controlling klinischer Studien auf ein neues Level zu bringen?</h4>
            <a className="btn btn-sm btn-secondary" href={`https://trialytix.io/de/contact/`}>Vertrieb kontaktieren</a>
          </div>

          <div className="col-md-6 col-lg-6 mb-5">
            <h5 className="text-white">Wir unterstützen Life-Science-Unternehmen bei der Durchführung klinischer Studienprojekte, indem wir ihnen eine innovative Platform zur Steuerung ihrer klinischen Studienprojekte bieten.</h5>
          </div>

          <div className="footer__logo col-lg-2">
             <NavbarLogo forceDarkMode={true}/>
          </div>
        </div>

        <hr className="text-white" />

        <div className="row py-5">
          <div className="footer__navlist col-lg-3 col-md-6 col-sm-6 col-6">
            <span className="text-white">Capabilites</span>
            <ul className="footer__lists">
              <li><a href={`https://trialytix.io/de/budgeting`}>Budgetverwaltung</a></li>
              <li><a href={`hhttps://trialytix.io/de/project-oversight`}>Projekt- und Aufgabenbearbeitung</a></li>
              <li><a href={`https://trialytix.io/de/accounting`}>Buchhaltung</a></li>
            </ul>
          </div>

          <div className="footer__navlist col-lg-3 col-md-6 col-sm-6 col-6">
            <span className="text-white">Unternehmen</span>
            <ul className="footer__lists">
              <li><a href={`https://trialytix.io/de/why-trialytix/`}>Warum Trialytix?</a></li>
              <li><a href={`https://www.trialytix.io/de/customers`}>Kunden</a></li>
              <li><a href={`https://www.trialytix.io/de/demo`}>Kostenlose Demo erhalten</a></li>
              <li><a href={`https://www.trialytix.io/de/contact`}>Kontakt aufnehmen</a></li>
            </ul>
          </div>

          <div className="footer__navlist col-lg-3 col-md-6 col-sm-6 col-6">
            <span className="text-white">Rechtliches</span>
            <ul className="footer__lists">
              <li><a href={`https://www.trialytix.io/de/legal/imprint`}>Impressum</a></li>
              <li><a href={`https://www.trialytix.io/de/legal/dataProtection`}>Datenschutz</a></li>
            </ul>
          </div>

          <div className="footer__ctaContainer col-lg-3 col-md-6 col-sm-6 col-6">
          <div className="d-flex mb-4 list-unstyled">     
                </div> 
                <span className="text-white">Folgen Sie uns:</span>
                <SocialIcons />  
          </div>
        </div>
        </div>
        </section>
    )
};

export default AppFooter;