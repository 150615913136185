import React, { useContext } from "react";
import './LikedPostsWidget.css';

import { useFetch } from '../../../../hooks/useFetch';
import { AuthContext } from "../../../../context/AuthProvider";

import { Card } from "../../Card/Card";
import { DashboardArticlesList } from "../DashboardArticlesList/DashboardArticlesList";
import { LoadingSpinner } from "../../LoadingSpinner/LoadingSpinner";

import emptyStateIllustration from '../../../../assets/media/illustrations/illustration_like-action.png';
import IconInsights from '../../../../assets/icons/trialytix/Insights.svg';

export const LikedPostsWidget = () => {
    // Get the token from the AuthContext
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const userId = auth?.user.id;

    // Populate User-ID and Post-Title, then filter for User-ID and sort by createdAt
    // Then Fetch the data
    const requestUrl = `/post-likes?populate[users_permissions_user][fields][0]=id&populate[post][fields][0]=title&filters[users_permissions_user][id][$eq]=${userId}&sort=createdAt:desc`;

    const { data, loading, error } =  useFetch (requestUrl, token)

    // Get the liked posts from the data
    let userLikes = data;

    if (loading) {
        return <LoadingSpinner />
    }

    if (error) {
        return <p>There was an error loading your liked posts</p>
    }

     // Filter out any null or invalid posts
     userLikes = data.filter(like => like.post && like.post.title);
 
    return (
        <Card>
            <div className="card-body">
                <img src={IconInsights} style={{ height: '40px' }} className="mb-4"/>
                <h5 className="card-title fw-semibold mb-4 my-3">{userLikes.length === 0 ? "Speichern Sie wichtige Beiträge" : "Ihre gespeicherten Beiträge" }</h5>
                
                {userLikes.length === 0 ?
                    <img src={emptyStateIllustration} alt="Illustration die zeigt, dass ein Klick auf das Herz-Icon eines Beitrages, den Beitrag favorisiert" /> :
                    <DashboardArticlesList likes={userLikes} />
                }
            </div>
        </Card>
    )
};