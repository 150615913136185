import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../../context/AuthProvider.js"
import './NavItem.css'

export const NavItem = (props) => {
    const { label, referrer, requiresAuth } = props;
    const { isAuthenticated} = useContext(AuthContext);

    // Get the current user-location
    const location = useLocation();

    // Check for current page
    const isCurrentPage = location.pathname === `/${referrer}`;

    // If the NavItem requires authentication show the NavItem only if the user is authenticated
    // otherwise just show the NavItem

    if (requiresAuth && !isAuthenticated) {
        return null;
    } else {
        return (
            <li className="nav-item">
                <Link to={`/${referrer}`} className={`nav-item nav-link ${isCurrentPage ? "active" : null}`}>
                    {label}
                </Link>
            </li>
        );
    }
};