import { useState, useEffect } from 'react';

export const useDarkMode = () => {
    // Get Prefs for Dark Mode from System and Local Storage
    const systemPrefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const darkModePreference = localStorage.getItem('dark-mode');
    
    const [preference, setPreference] = useState();
    const html = document.querySelector('html');

    // Triggered on initial check of system preference
    // If no local storage preference is set, set the preference to the system preference
    // If a local storage preference is set, set the preference to the local storage preference
    useEffect (() => {
        if(systemPrefersDark === true && darkModePreference === null) {
            setPreference(true);
        } else if (systemPrefersDark === false && darkModePreference === null) {
            setPreference(false);
        } else if (darkModePreference === 'true') {
            setPreference(true);
        } else if (darkModePreference === 'false') {
            setPreference(false);
        }    
    }, [systemPrefersDark]);

    // Set the dark mode preference in the <html> element whenever the preference state changes
    // Sets a css class for Trialytix-Specific Darkmode Styles and a data-bs-theme attribute for Bootstrap 5 Darkmode Styles (Trialytix Css Class should have higher Precedence)
    useEffect(() => {
        if (preference === true){
            html.classList.add('dark-mode');
            html.setAttribute('data-bs-theme', 'dark');
        } else {
            html.classList.remove('dark-mode');
            html.removeAttribute('data-bs-theme');
        }
    }, [preference]);

    // Toggle the dark mode preference
    // Basically this ensures that local storage settings are only made if the user engages with the theme toggle
    const togglePreference = () => {
        if (preference === true) {
            localStorage.setItem('dark-mode', false);
            setPreference(false);
        } else {
            localStorage.setItem('dark-mode', true);
            setPreference(true);
        }
    };

    return { 
        preference, 
        togglePreference };
};