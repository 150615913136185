import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthProvider';
import { LogoutButton } from './LogoutButton';

export const LoginLogout = () => {
    const { isAuthenticated } = useContext(AuthContext);
    
    return (
        <>  
            { 
            isAuthenticated === true ? <li className="nav-item">
                <LogoutButton />
            </li> : null
            }

            {
            isAuthenticated === false ? <li className="nav-item me-2">
                <Link to="/sign-in" className="btn btn-primary">
                    Login
                </Link>
            </li> : null
            }

            {
            isAuthenticated === false ? <li className="nav-item">
                <Link to="/sign-up" className="btn btn-secondary">
                    Request Access
                </Link>
            </li> : null
            }
        </>
    );
};