import React from 'react';

export const SocialIcons = () => {
    return (
      <div className="d-flex">
        <div className="mt-3">
          <a href="https://www.linkedin.com/company/71514019/">
            <img
              className="footer__socialIcon mr-3"
              src="/socialIcons/linkedin.svg"
              alt='Link to LinkedIn'
            />
          </a>
          <a href="https://www.instagram.com/trialytix/">
            <img
              className="footer__socialIcon mr-3"
              src="/socialIcons/instagram.svg"
              alt='Link to Instagram'
            />
          </a>
          <a href="https://www.facebook.com/Trialytix-110886891713490">
            <img
              className="footer__socialIcon mr-3"
              src="/socialIcons/facebook.svg"
              alt='Link to Facebook'
            />
          </a>
        </div>
      </div>
    );
  };
