import React from 'react'

const CtaSectionSupport = () => {
  return (
    <section className="py-5 bg-grey-100">
        <div className="container text-center">
            <h2 className="fw-semibold accentText fs-1">Benötigen Sie weitere Hilfe?</h2>
            <p className="mt-3 fw-medium col-grey-600">Setzen Sie sich mit unserem Support in Verbindung!</p>
            <a href="mailto:info@trialytix.io">
                <button className="btn btn-primary px-5 mt-5">Anfrage stellen</button>
            </a>
        </div>
    </section>
  )
}

export default CtaSectionSupport