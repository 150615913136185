import { useState, useEffect } from 'react';

import { API } from '../data/constant';

export const useFetch = (url, accessToken) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        
        const fetchData = async () => {
            try {
                const response = await fetch(`${API}${url}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`Error fetching data from ${API}${url}: ${response.status}`);
                }

                const data = await response.json();
                setData(data);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        }

        fetchData();
    }, [url]);

    return { data, loading, error };
};