import React, { useEffect, useState } from 'react';
import './PostCard.css';

import { RouterLink } from "../Link/Link";
import { LikeButton } from "../LikeButton/LikeButton";
import { Card } from "../Card/Card";

export const PostCard = (props) => {
    const { post } = props;
    const { id: postId, post_likes, post_category: { categoryName }, isPremium } = post;

    // State for likeId
    const [likeId, setLikeId] = useState(null);
    let likedAlready = post_likes && post_likes.length > 0;

    useEffect(() => {
        // If the post has any likes by the requesting user, the post is marked as likedAlready
        likedAlready = post_likes && post_likes.length > 0;
        setLikeId(likedAlready ? post.post_likes[0]?.id : null);
    }, [post]);

    return (
        <Card hasHover="true">
            <div className="card-header d-flex align-items-center justify-content-between">
                <div>
                    <h6 className="mb-2 badge bg-secondary-tint2 text-white fw-medium fs-7 mt-2">{categoryName}</h6>
                    { isPremium ? <h6 className="mb-2 badge bg-primary text-white fw-medium fs-7 mt-2 ms-1">Premium</h6> : ""}
                </div>
                <div className='d-flex flex-row'>
                    <LikeButton postId={postId} likedAlready={likedAlready} likeId={likeId}/>
                </div>
            </div>
            
            <div className="card-body d-flex flex-column justify-content-between">
                <div>
                    <h5 className="card-title mb-3">{post.title}</h5>
                    <p className="card-text mb-4 fs-6">{post.shortDescription}</p>
                </div>
                <RouterLink referrer={`/posts/${post.id}`} label="Read more" classes="col-primary fw-medium" />
            </div>
        </Card>
    )
};
