import React from 'react';
import { useDarkMode } from '../hooks/useDarkMode';

// Create a context for the theme
export const ThemeContext = React.createContext();

// Create a provider for components to consume and subscribe to changes
export const ThemeProvider = ({ children }) => {
    const theme = useDarkMode();

    return (
        <ThemeContext.Provider value={theme}>
            {children}
        </ThemeContext.Provider>
    )
}