import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import posthog from 'posthog-js';

import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { ThemeProvider } from './context/ThemeProvider';

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Custom styles
import './styles/fonts.css';
import './index.css'
import './styles/styleguide.css';
import './styles/bootstrapExpansion.css';

posthog.init(
    'phc_XIKrsEhwVvBjf5A9g0smf65HAEJHvSNfWooTwSAQZHG', { api_host: "https://app.posthog.com" }
)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <AuthProvider>
            <ThemeProvider>
                <Router>
                    <App />
                </Router>
            </ThemeProvider>
        </AuthProvider>
    </React.StrictMode>
);