import React, {useEffect} from 'react';
import { useLikes } from '../../../hooks/useLikes';
import './LikeButton.css';

import { Icon } from "../Icon/Icon";

export const LikeButton = (props) => {
    const { postId, likedAlready, likeId} = props;
    const [ isLiked, likePost, unlikePost, setIsLiked ] = useLikes();

    const toggleLike = () => {
        if (isLiked) {
            unlikePost(likeId);
        } else {
            likePost(postId);
        }
    };

    // likedAlready is a boolean that is passed from the parent component (PostCard) to LikeButton
    // calculated by checking if the post has any likes by the requesting user in the database
    useEffect(() => {
        if (likedAlready === true) {
            setIsLiked(true);
        }
    }, [postId]);

    return(
        <button 
            className="favorise-button" 
            onClick={toggleLike}>
            <Icon 
                pack={`${isLiked ? "solid" : "regular"}`} 
                icon="heart" 
                className={`icon favorise-button-icon ${isLiked ? "col-primary" : ""}`}  
            />
        </button>
    )
};