import React, { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from '../../data/constant';
import { AuthContext } from '../../context/AuthProvider';

import { LoadingSpinner } from '../../components/ui/LoadingSpinner/LoadingSpinner';

export const SignInForm = () => {
    const userRef = useRef();
    const navigate = useNavigate();
    const { setAuth } = useContext(AuthContext);

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [authComplete, setAuthComplete] = useState(false);

    // Set the focus on the username input field when the component is mounted
    useEffect(() => {
        userRef.current.focus();
    }, []);

    // Reset the error message when the user or password changes
    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);

    // Check if the authentication is complete and redirect to the dashboard
    useEffect(() => {
        if (authComplete) {
          navigate("/dashboard");
        }
      }, [authComplete, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submit action (Page reload)
        setIsLoading(true);

        try {
            // Send the login request to the API to authenticate the user
            const response = await fetch(`${API}/auth/local`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    identifier: user,
                    password: pwd,
                }),
            });

           // Backend responds with 400 in case of wrong credentials. 

            if(response.ok) {
                const data = await response.json();           
                const user = data.user;
                const accessToken = data.jwt;

                // Store the user and access token in the local storage
                setAuth({ user, accessToken });
                setAuthComplete(true); // Set the isAuthComplete state variable to true

            } else if (response.status === 400) {
                setErrMsg('Benutzer:innen-Name oder Passwort falsch. Bitte versuchen Sie es erneut.');
            }else {
                // Handle other status codes
                setErrMsg('Ein unerwarteter Fehler ist aufgetreten. Wir bitten um Entschuldigung. Bitte versuchen Sie es später erneut.');
            }
        } catch (error) {
            console.log(error.message);
            if(!error.response){
                setErrMsg("Der Server antwortet gerade nicht. Wir bitten um Entschuldigung. Bitte versuchen Sie es später erneut.")
            }
        } finally {
            setIsLoading(false);
        }
    };
    
    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <form className="form-horizontal d-flex flex-column align-items-center container" onSubmit={handleSubmit}>
                <div className="form-group w-100 row py-2 m-1">
                    <label htmlFor="username" className="control-label mb-2">
                        Username
                    </label>
                    <div className="sign-in-inputfield">
                        <input 
                            type="text" 
                            id="username" 
                            name="username" 
                            placeholder="Username" 
                            ref={userRef}
                            autoComplete='off'
                            onChange={e => setUser(e.target.value)}
                            value={user}
                            required
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="form-group w-100 row py-2">
                    <label htmlFor="password" className="control-label mb-2">
                        Passwort
                    </label>
                    <div className="sign-in-inputfield">
                        <input 
                            type="password" 
                            id="password" 
                            name="password" 
                            onChange={e => setPwd(e.target.value)}
                            value={pwd}
                            placeholder="Passwort" 
                            required 
                            className="form-control" 
                        />
                    </div>
                </div>

                {errMsg && (
                    <div className="form-group my-3 d-flex justify-content-center">
                            <div className="alert alert-danger">{errMsg}</div>
                    </div>
                )}

                <div className="form-group mt-3 d-flex w-100" id='loginbutton'>
                        <button disabled={user && pwd ? false : true } type="submit" className="btn btn-primary w-100" >
                            Anmelden
                        </button>
                        </div>
            </form>
        </>
    )
}