import React from 'react';
import './DashboardArticleLink.css';

import { Icon } from '../../Icon/Icon';

export const DashboardArticleLink = (props) => {
    const { title } = props;

    return (
        <div className="d-flex flew-row justify-content-between align-items-center py-2 px-3 bg-grey-100 rounded dashboard-article-link">
            <span className='fs-7'>{title}</span>
            <Icon pack="solid" icon="arrow-right" color="col-primary" />
        </div>
    )
}