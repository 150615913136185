import React from "react";
import { Link } from "react-router-dom";
import './SignIn.css';

import { SignInForm } from "../../features/AuthProvider/SignInForm.jsx";

import StandardLayout from "../../layouts/StandardLayout.jsx";

import logoIcon from '../../assets/brand/clientlogo_icon_dark.png';
import { CtaSectionWaves } from "../../components/sections/CtaSection/CtaSectionWaves/CtaSectionWaves.jsx";
import LoginPageAd from "../../components/sections/LoginPageAd/LoginPageAd.jsx";

export const SignIn = () => {


    return (
      <StandardLayout backgroundClass={'backgr_grad-2'}>
        <div id="LoginField" className="container elev-500 rounded mb-5 col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
            <div className="d-flex flex-column py-5">
              <div className="d-flex flex-column align-items-center">
              <img src={logoIcon} alt="Logo Icon der Trialytix GmbH" className="sign-in-logo-icon mb-5" />
              <h1 className="mb-3 fw-bold accentText-2">Login Academy</h1>
              </div>
              <SignInForm />
              
              <div className="d-flex flex-row justify-content-center">
              <p className="mx-2 mt-5">
                Sie benötigen einen Account? 
              </p>
              <span className="fw-bold col-primary mt-5">
                <Link to="/sign-up">Hier registrieren</Link>
              </span>
              </div>
          </div>
        </div>
        <LoginPageAd />
        <CtaSectionWaves />
      </StandardLayout>
    )
};