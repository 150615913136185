import React from 'react';
import { useAuth } from '../hooks/useAuth';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  // Provider component that wraps the app and makes auth object available to any child component that calls useAuth()
  const auth = useAuth();

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
};