import React from 'react';
import './Card.css';

export const Card = (props) => {
    const { children, hasHover } = props;

    /*
        Add 'hasHover' prop to add brand hover effect to card
    */

    return (
        <div className={`card elev-100 border-radius-15 h-100 bg-background-color col-grey-900 border border-1 ${hasHover ? "card-hover" : "" }`}>
            {children}
        </div>
    )
};