import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import posthog from 'posthog-js';

import { AuthContext } from "./context/AuthProvider";
import { ThemeContext } from "./context/ThemeProvider";

import AppHeader from "./layouts/AppHeader/AppHeader";
import AppRoutes from "./Routes";

const App = () => {
  // Kind of WIP, might be better to refactor this
  // Get the current auth and theme state from contexts, store them in state and pass to AppHeader as Prop
  // This forces the Appheader and it's children to re-render when the context changes
  // Single use cases are: Change Logo when Theme-Pref changes and Switch out Buttons when Auth changes
  
  const { auth } = useContext(AuthContext);
  const [ authState, setAuthState] = useState(auth);

  const { preference } = useContext(ThemeContext);
  const [themeState, setThemeState] = useState(preference);

  let location = useLocation();

  useEffect(() => {
    setAuthState(auth);
  }, [auth]);

  useEffect(() => {
    setThemeState(preference);
  }, [preference]);

  // Capture a Posthog Pageview every time user navigates. Necessary for Posthog to work in SPA structure of our frontend
  useEffect(() => {
    posthog.capture('$pageview');
  }, [location]);

  return (
    <>
      <AppHeader auth={authState} theme={themeState} />
      <AppRoutes />
    </>
  )
}

export default App;
