import { jwtDecode } from "jwt-decode";

// Check if JWT is expired
export const useAuthExpiry = (auth) => {
  // Check if auth is null or accessToken is null
  if(auth !== null && auth.accessToken !== null) {
      // Decode JWT to get timestamp
      var decodedTimestamp = jwtDecode(auth.accessToken).exp;
      // Check if timestamp is expired
      return isTimestampExpired(decodedTimestamp)
  }

  return true
};

// Check if timestamp is expired
const isTimestampExpired = (timestamp) => {
    // Get current time
    const now = new Date();
    // Get current time and convert from miliseconds to seconds
    const nowUnix = now.getTime() / 1000; 
    
    // Return true if timestamp is less than current time aka expired
    return nowUnix > timestamp
};