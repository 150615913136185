import React from "react";
import { Link } from "react-router-dom";

import { DashboardArticleLink } from "../DashboardArticleLink/DashboardArticleLink";

export const DashboardArticlesList = (props) => {
    const { likes } = props;

    return (
        <div className="list-container">
            <ul>
                {likes?.map((like) => {
                    let post = like.post
                    return (
                        <li className="mb-1" key={post.id}>
                            <Link to={`/posts/${post.id}`}>
                                <DashboardArticleLink title={post.title} />
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
};