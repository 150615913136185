import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

const AUTH_STORAGE_KEY = "trialytix-auth";

// Custom hook to manage auth state and persist it to local storage
export const useAuth = () => {
  const [auth, setAuth] = useState(() => {
    try {
      // Get auth from local storage
      const storedAuth = JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY));

      // Check if auth is expired and remove if it is
      if (isAuthExpired(storedAuth) === true) {
        localStorage.removeItem(AUTH_STORAGE_KEY);
      };

      return storedAuth;

    } catch (error) {
        // Catch any errors
        console.error("Error parsing stored auth:", error);
        // Return null if error
        return { user: null, accessToken: null };
    }
  });

  const signOut = () => {
    setAuth(null);
    localStorage.removeItem(AUTH_STORAGE_KEY);
  };

  // Persist auth to local storage when it changes
  useEffect(() => {
      if(auth !== null){
        localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(auth));
      }
  }, [auth]);

  const isAuthenticated = !isAuthExpired(auth);

  return { 
    auth,
    isAuthenticated,
    setAuth,
    signOut
  };
};

// Check if JWT is expired
const isAuthExpired = (auth) => {
  // Check if auth is null or accessToken is null
  if(auth !== null && auth.accessToken !== null) {
      // Decode JWT to get timestamp
      var decodedTimestamp = jwtDecode(auth.accessToken).exp;
      // Check if timestamp is expired
      return isTimestampExpired(decodedTimestamp)
  }

  return true
};

// Check if timestamp is expired
const isTimestampExpired = (timestamp) => {
    // Get current time
    const now = new Date();
    // Get current time and convert from miliseconds to seconds
    const nowUnix = now.getTime() / 1000; 
    
    // Return true if timestamp is less than current time aka expired
    return nowUnix > timestamp
};