import React from 'react';
import './PostCatSelector.css';

import { Icon } from '../../components/ui/Icon/Icon';
import { LoadingSpinner } from '../../components/ui/LoadingSpinner/LoadingSpinner';

export const PostCatSelector = ({ categories, selectedFilter, toggleFilter, clearFilter, isLoading, error }) => {

    if(isLoading) {
        return <LoadingSpinner />
    }

    if(error) {
        return "Es gab ein Problem die Daten zu holen. Wir bitten dies zu entschuldigen. Bitte kehren Sie später zurück!"
    }

    return (
        <div className="category-selector-container p-1">
            <button 
                className={`category-selector-button elev-100 ${selectedFilter === null ? 'cat-selector-selected' : ''}`} 
                onClick={clearFilter}>
                Alle Beiträge
            </button>

            <button 
                className={`category-selector-button elev-100 ${selectedFilter === 'liked' ? 'cat-selector-selected' : ''}`} se
                onClick={() => toggleFilter("liked")}>
                <Icon icon="heart" pack="solid" className="col-primary mx-3" />
            </button>
            
            {categories && categories.data.map((category) => (
                <button 
                    className={`category-selector-button elev-100 ${selectedFilter === category.id ? 'cat-selector-selected' : ''}`} 
                    key={category.id}
                    onClick={() => toggleFilter(category.id)}
                    >
                    {category.attributes.categoryName}
                </button>
            ))}

        </div>
    )
};