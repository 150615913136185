import { Navigate } from 'react-router-dom';
import { useAuthExpiry } from '../../hooks/useAuthExpiry.js';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthProvider.js';

export const ProtectedRoute = ({ auth, children }) => { 
    const { signOut } = useContext(AuthContext);
    // Check if the authentication is expired
    const isAuthExpired = useAuthExpiry(auth);

    // If the authentication is not expired, render the children
    if (isAuthExpired !== true) {
        return children;
    }
    
    // If the authentication is expired sign user out and redirect to the sign-in page
    signOut();
    return <Navigate to="/sign-in" replace />; 
};