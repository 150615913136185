import React, { useContext } from "react";
import { ThemeContext } from "../../../context/ThemeProvider";

import "./ThemeToggle.css";
import { Icon } from "../Icon/Icon";

export const ThemeToggle = () => {
    
    const { preference, togglePreference } = useContext(ThemeContext);

    // Toggle the dark mode preference on click of the toggle
    const handleClick = () => {
        togglePreference();
    };

    return (
        <button className="btn btn-secondary theme-toggle me-2" onClick={handleClick}>
            <Icon pack="regular" icon={preference === true ? 'lightbulb' : 'moon'} color="primary" />
        </button>
    )
};