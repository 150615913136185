import React from 'react';
import PropTypes from 'prop-types';
import CopyrightNote from './CopyrightNote/CopyrightNote';
import AppFooter from './AppFooter/AppFooter';
import './StandardLayout.css';

const StandardLayout = ({ children, backgroundClass }) => {
    return (
        <div id="standardLayout" className="d-flex flex-column">
            <div className={`flex-grow-1 pt-7 ${backgroundClass ? backgroundClass : ''}`}>
                {children}
            </div>
            <AppFooter />
            <CopyrightNote />
        </div>
    );
};

export default StandardLayout;
