import React from 'react';
import './CtaSectionWaves.css';

export const CtaSectionWaves = ({
  heading = 'Bereit loszulegen?',
  subheading = 'Lassen Sie Trialytix die Magie hinter Ihren erfolgreichen Abläufen sein und treten Sie in Kontakt',
  buttonText = 'Vertrieb kontaktieren',
  buttonLink = 'https://trialytix.io/de/contact/'
}) => {
  return (
    <section className='backgr_grad text-center'>
      <div className="container">
        <div className="row py-7">
          <h1 className="text-white">{heading}</h1>
          <p className="text-white my-3 lead">{subheading}</p>
          <div>
            <a href={buttonLink} className="btn btn-primary me-3">{buttonText}</a>
          </div>
        </div>
      </div>
      
      <div>
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x={48} y={0} fill="rgba(35,44,88,0.7" />
            <use xlinkHref="#gentle-wave" x={48} y={3} fill="rgba(35,44,88,0.5)" />
            <use xlinkHref="#gentle-wave" x={48} y={5} fill="rgba(35,44,88,0.3)" />
            <use xlinkHref="#gentle-wave" x={48} y={7} fill="rgba(35,44,88,1)" />
          </g>
        </svg>
      </div>
    </section>
  );
};
