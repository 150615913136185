import React from "react";

import { PostCard } from "../PostCard/PostCard";
import { LoadingSpinner } from "../../ui/LoadingSpinner/LoadingSpinner";
import { RouterLink } from "../Link/Link.jsx"

import emptyStateIllustration from '../../../assets/media/illustrations/illustration_like-action.png'

export const PostList = (props) => {
    const { posts, selectedFilter, isLoading, error} = props;

    if (isLoading) {
        return <LoadingSpinner />
    }

    if (error === 403) {
        return(
            <div className="card bg-grey-800 text-white elev-300">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <img src={ require("../../../assets/media/clak_illustrations/Support.png")} alt="Support Illustration"/>
                        </div>
                        <div className="col-12 col-md-8 d-flex align-items-center">
                            <div>
                                <h5 className="mb-3">Oops, hierfür sind Sie <em>noch</em> nicht freigeschaltet!</h5>
                                <p>Wie es aussieht versuchen Sie einen Inhalt aufzurufen, für den Sie erst freigegeben werden müssen.</p>
                                <p className="mb-3">Versuchen Sie es später erneut oder wenden Sie sich an den Support.</p>
                                <RouterLink referrer="mailto:max@space-4.de" label="Support" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
      }

    if(selectedFilter === 'liked' && posts.length === 0) {
        return (
            <>
                <h4 className="mb-2">Sie haben noch keine Beiträge gespeichert!</h4>
                <p className="mb-5">Merken Sie sich wichtige Beiträge vor, damit Sie sie später schneller wiederfinden!</p>
                <img src={emptyStateIllustration} alt="Illustration die zeigt, dass ein Klick auf das Herz-Icon eines Beitrages, den Beitrag favorisiert" />
            </>
        )
    }

    return(
        <div className="row g-4">
            {posts && posts.map((post) => (
              <div className="col-12 col-md-6 col-lg-4" key={post.id}>
                    <PostCard post={post} />
              </div>
          ))}
        </div>
    )
};