import { useState, useContext } from 'react';

import { AuthContext } from '../context/AuthProvider.js';
import { API } from '../data/constant.js'

export const useLikes = () => {
  const [isLiked, setIsLiked] = useState(false);

  // Get the token from the AuthContext
  const { auth } = useContext(AuthContext);
  const userId = auth?.user.id;
  const token = auth?.accessToken;

  // On succesful like-request, isLiked-sate is set to true
  const likePost = async (postId) => {
    const data = {
      data: {
        post: postId,
        users_permissions_user: userId
      }
    };

    try {
      await fetch(`${API}/post-likes`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      setIsLiked(true);
    } catch (error) {
      console.error('There was a problem liking the post:', error);
    }
  };

  // On succesful unlike-request, isLiked-sate is set to false
  const unlikePost = async (likeId) => {
    try {
      await fetch(`${API}/post-likes/${likeId}`, { 
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          },
         });
      setIsLiked(false);
    } catch (error) {
      console.error('There was a problem unliking the post:', error);
    }
  };

  return [isLiked, likePost, unlikePost, setIsLiked];
};