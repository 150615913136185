import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "../Icon/Icon";
import './Link.css'

export const RouterLink = (props) => {
    const { referrer, label, classes } = props;

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <span className={`${classes}`}>
            <Link className={`label ${isHovered ? 'label-active' : ''}`} to={referrer} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <span >{label}</span> 
                <Icon pack="solid" icon="arrow-right" className={`icon ${isHovered ? 'icon-active' : ''}`} />
            </Link>
        </span>
    )
};