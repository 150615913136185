import React from 'react';
import CopyrightNote from '../CopyrightNote/CopyrightNote';
import AppFooter from '../AppFooter/AppFooter';
import './LandingPageLayout.css'

const StandardLayout = ({ children }) => {
    return (
        <>
        <div id="LandingPageLayout" className="d-flex flex-column">
            <div className='flex-grow-1'>
                {children}
            </div>
            
            <AppFooter />
            <CopyrightNote/>
        </div>

        </>
    )
};

export default StandardLayout;