import React from 'react';
import { Link } from 'react-router-dom';

import { SignUpForm } from '../../features/AuthProvider/SignUpForm.jsx';

import StandardLayout from '../../layouts/StandardLayout.jsx';

import logoIcon from '../../assets/brand/clientlogo_icon_dark.png';
import './SignUp.css'
import LoginPageAd from '../../components/sections/LoginPageAd/LoginPageAd.jsx';

export const SignUp = () => {
  

  return (
    <>
    <StandardLayout backgroundClass={'backgr_grad-2'}>
    <div id="SignUpBox" className="container elev-500 rounded mb-5 d-flex justify-content-center align-items-center">
        <div className="col-md-12 px-sm-3 col-xl-11 py-5 d-flex flex-column">

          <img src={logoIcon} alt="Logo Icon der Trialytix GmbH" className="sign-in-logo-icon mb-5 align-self-center" />

          <h1 className="mb-3 fw-bold accentText-2 fs-2">Academy Account anlegen:</h1>

          <p className='mb-4 fs-7'>Legen Sie hier Ihren Account für die Trialytix Academy an. Wir werden Ihren Account im Service Center manuell freischalten.</p>

          <SignUpForm />

          <div className='d-flex flex-row align-items-center mt-5'>
          <p className='px-2'>
            Sie haben bereits einen Account? 
          </p>

          <span className="fw-bold col-primary">
            <Link to="/sign-in">Hier einloggen</Link>
          </span>
          </div>
      </div>
    </div>
    <LoginPageAd />
    </StandardLayout>
    </>
  );
};